/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import { Link, graphql } from 'gatsby';
import '../style/app.scss';
import Img from 'gatsby-image';
import styled from 'styled-components';
import { device } from '../lib/device';
import Footer from '../components/Footer';
import Header from '../components/Header';
import Share from '../images/share.png';
import IconInstagram from '../components/svg/IconInstagram';
import IconFacebook from '../components/svg/IconFacebook';
import IconPinterest from '../components/svg/IconPinterest';

const SinglePainting = styled.div`
	display: grid;
	grid-template-columns: 1fr 1fr;
	gap: 100px;
	margin: 100px 0;
	@media ${device.desktopHhd} {
		margin: 100px 20px;
	}
	@media ${device.desktop} {
		gap: 50px;
	}
	@media ${device.mobile} {
		display: block;
	}
	.image_painting {
		padding-right: 103px;
		position: relative;
		height: auto;
		width: 100%;
		@media ${device.desktop} {
			padding-right: 51px;
		}
		@media ${device.mobile} {
			padding-right: 0;
			margin-bottom: 40px;
		}
		&:after {
			position: absolute;
			right: 0;
			top: 50%;
			transform: translate(-50%, -50%);
			width: 1px;
			height: 100%;
			background-color: #bcbcbc;
			content: ' ';
			display: block;
			@media ${device.mobile} {
				right: auto;
				top: auto;
				bottom: -20px;
				left: 50%;
				width: 100%;
				height: 2px;
			}
		}
		img {
			border: 4px solid #bcbcbc;
		}
	}
	.right_side {
		position: relative;
		h2 {
			font-family: var(--font_harmoniasanspro_light);
			font-size: 60px;
			font-weight: 100;
			margin-top: 0;
			@media ${device.desktopHhd} {
				font-size: 40px;
			}
		}
		p {
			font-family: var(--font_harmoniasanspro_light);
			color: var(--brand_black);
			font-size: 20px;
			@media ${device.tablet} {
				font-size: 18px;
			}
			a {
				color: #4cb7e3;
				text-decoration: none;
				&:hover {
					text-decoration: underline;
				}
			}
		}
		.contact {
			form {
				display: block;
				input,
				textarea {
					display: block;
					width: 100%;
					box-sizing: border-box;
					padding: 15px;
					padding-bottom: 5px;
					padding-left: 0;
					border: none;
					border-bottom: 1px solid #bcbcbc;
					outline: none;
					font-family: var(--font_harmoniasanspro_light);
					font-weight: 100;
					font-size: 20px;
					color: var(--brand_black);
					margin-bottom: 15px;
					&::-webkit-input-placeholder {
						color: #bcbcbc;
					}
					&::-moz-placeholder {
						color: #bcbcbc;
					}
					&:-ms-input-placeholder {
						color: #bcbcbc;
					}
					&:-moz-placeholder {
						color: #bcbcbc;
					}
				}
				.half {
					display: grid;
					grid-template-columns: 1fr 1fr;
					gap: 13px;
				}
				textarea {
					height: 140px;
					resize: none;
					border: 1px solid #bcbcbc;
					padding: 15px;
				}
				label {
					display: flex;
					align-items: center;
					font-family: var(--font_harmoniasanspro_light);
					line-height: 1.1;
					font-size: 16px;
					&.agreements {
						display: block;
					}
					@media ${device.desktop} {
					}
					a {
						color: var(--brand_blue);
						text-decoration: none;
						margin: 0 5px;
					}
					input {
						border-radius: 2px;
						border: 1px solid #bcbcbc;
						display: inline-block;
						width: auto;
						width: 16px;
						height: 16px;
						margin: 0;
						margin-right: 10px;
					}
				}
				p {
					font-family: var(--font_harmoniasanspro_light);
					font-weight: 100;
					font-size: 20px;
					margin-bottom: 5px;
					color: #bcbcbc;
				}
				.hidden {
					display: none;
				}
				button {
					outline: none;
					font-family: var(--font_harmoniasanspro_light);
					float: right;
					border: 1px solid var(--brand_blue);
					padding: 5px 20px;
					text-decoration: none;
					background-color: white;
					font-size: 20px;
					margin-top: 10px;
					color: var(--brand_blue);
					cursor: pointer;
					display: block;
					transition: all 0.4s ease;
					&:hover {
						background-color: var(--brand_blue);
						color: white;
					}
				}
				.contact_form_info {
					padding-top: 10px;
					overflow: auto;
					width: 100%;
					.success {
						color: green;
					}
					.error {
						color: red;
					}
				}
			}
			.share {
				position: relative;
				color: var(--brand_blue);
				display: grid;
				grid-template-columns: 30px auto auto;
				width: 30%;
				align-items: center;
				margin-top: 22px;
				@media ${device.desktop} {
					width: 50%;
				}
				@media ${device.tablet} {
					width: 60%;
				}
				ul {
					display: flex;
					list-style-type: none;
					margin: 0;
					padding: 0;
					li {
						margin-right: 10px;
						svg {
							width: 16px;
							height: 16px;
						}
					}
				}
			}
		}
	}
`;

function encode(data) {
	return Object.keys(data)
		.map((key) => `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`)
		.join('&');
}

const Painting = ({ data }) => {
	const painting = data.wpPainting;

	const [mailSent, setMailSent] = useState(false);
	const [error, setError] = useState(false);
	const [firstName, setFirstName] = useState('');
	const [lastName, setLastName] = useState('');
	const [email, setEmail] = useState('');
	const [subject, setSubject] = useState('');
	const [phone, setPhone] = useState('');
	const [message, setMessage] = useState('');

	function submitForm(e) {
		e.preventDefault();
		setSubject(painting.title);
		const paintingUrl = window.location.href;
		fetch('/', {
			method: 'POST',
			headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
			body: encode({ 'form-name': 'contact', firstName, lastName, email, phone, subject, message, paintingUrl }),
		})
			.then(() => setMailSent(true))
			.catch((error) => setError(true));
	}

	const isSolgt = painting.categories.nodes.filter((category) => category.slug === 'solgt');

	return (
		<>
			<Header />
			<div className="container">
				<SinglePainting>
					<div className="image_painting">
						<Img fluid={painting.featuredImage.node.localFile.childImageSharp.fluid} />
					</div>
					<div className="right_side">
						<h2>{painting.title}</h2>
						<h4>
							{painting.categories.nodes.map((category, i) => (
								<span key={category.slug}> {category.name}</span>
							))}
						</h4>
						<div
							dangerouslySetInnerHTML={{
								__html: painting.content,
							}}
						/>

						<div className="contact">
							{(() => {
								if (isSolgt.length < 1) {
									return (
										<>
											<h4>INQUIRY</h4>

											<form
												name="inquiry"
												data-netlify="true"
												data-netlify-honeypot="bot-field"
												action=""
												onSubmit={submitForm}
											>
												<input type="text" name="bot-field" className="hidden" />
												<div className="half">
													<input
														type="text"
														placeholder="First Name"
														name="firstName"
														onChange={(e) => setFirstName(e.target.value)}
													/>
													<input
														type="text"
														placeholder="Last Name"
														name="lastName"
														onChange={(e) => setLastName(e.target.value)}
													/>
												</div>
												<div className="half">
													<input
														type="email"
														placeholder="Email"
														name="email"
														onChange={(e) => setEmail(e.target.value)}
													/>
													<input
														type="number"
														placeholder="phone"
														name="phone"
														onChange={(e) => setPhone(e.target.value)}
													/>
												</div>
												<p>Notes</p>
												<textarea name="message" onChange={(e) => setMessage(e.target.value)} />
												<label htmlFor="agreements" className="agreements">
													<input type="checkbox" name="agreements" id="agreements" required />
													I have read and agree with{' '}
													<Link to="/terms-and-conditions">
														Privacy Policy & Terms and Condititions.
													</Link>
												</label>
												<button type="submit">Send</button>
												<div className="contact_form_info">
													{mailSent ? (
														<div className="success">Inqury successfully sent!</div>
													) : (
														''
													)}
													{error ? (
														<div className="error">
															There was an error, please try again later.
														</div>
													) : (
														''
													)}
												</div>
											</form>
										</>
									);
								}
							})()}
							<div className="share">
								<img src={Share} alt="" /> Share
								<ul>
									<li className="pinterest">
										<a target="_blanc" href="https://pinterest.com">
											<IconPinterest />
										</a>
									</li>
									<li className="instagram">
										<a target="_blanc" href="https://instagram.com">
											<IconInstagram />
										</a>
									</li>
									<li className="facebook">
										<a target="_blanc" href="https://facebook.com">
											<IconFacebook />
										</a>
									</li>
								</ul>
							</div>
						</div>
					</div>
				</SinglePainting>
			</div>
			<Footer />
		</>
	);
};

export const pageQuery = graphql`
	query($id: String!) {
		wpPainting(id: { eq: $id }) {
			title
			content
			categories {
				nodes {
					name
					slug
				}
			}
			featuredImage {
				node {
					localFile {
						childImageSharp {
							fixed {
								...GatsbyImageSharpFixed
							}
							fluid {
								...GatsbyImageSharpFluid
							}
						}
					}
				}
			}
		}
	}
`;

export default Painting;
